import { ISalaryACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from "@acl/modules";

export const salaryAcl: ISalaryACL = {
  parent: { action: "employee-salaries", resource: "employee-salaries" },
  employeeSalary: {
    ...commonFieldAcl("employee-salaries"),
    updateStatus: { action: "updateStatus", resource: "employee-salaries" },
    calculate: { action: "calculate", resource: "employee-salaries" },
    unassign: { action: "unassign", resource: "employee-salaries" },
  },
  request: {
    ...commonFieldAcl("payroll-adjustments"),
    payslipCorrections: {
      ...commonFieldAcl("payslip-corrections"),
      updateStatus: { action: "updateStatus", resource: "payslip-corrections" },
    },
    updateStatus: { action: "updateStatus", resource: "payroll-adjustments" },
  },
};
