import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  ATTENDANCE_METHOD,
  ATTENDANCE_METHOD_EMPLOYEE_FROM,
  ATTENDANCE_METHOD_FORM,
  ATTENDANCE_METHOD_GENERAL_FROM,
  ATTENDANCE_POLICY,
  HR_ATTENDANCE,
  OVERTIME_POLICY,
} from "./path-name.route";

export const AttendanceManagementRoutes: IRouteList[] = [
  {
    path: HR_ATTENDANCE,
    component: lazy(() => import("@pages/Settings/AttendanceManagement/AttendanceManagement")),
    meta: {
      action: hrmSettingsAcl.attendancePolicies.parent.action,
      resource: hrmSettingsAcl.attendancePolicies.parent.resource,
    },
    // meta: [
    //   hrmSettingsAcl.attendancePolicies.lateEarly.getAll,
    //   hrmSettingsAcl.attendancePolicies.overtimePolicies.getAll,
    //   hrmSettingsAcl.attendancePolicies.trackingMethod.getAll,
    // ],
    children: [
      {
        path: ATTENDANCE_POLICY,
        component: lazy(() => import("@pages/Settings/AttendanceManagement/AttendancePolicy/HRAttendancePolicy")),
        meta: hrmSettingsAcl.attendancePolicies.lateEarly.getAll,
      },
      {
        path: ATTENDANCE_METHOD,
        component: lazy(() => import("@pages/Settings/AttendanceManagement/AttendanceMethods/AttendanceMethods")),
        meta: hrmSettingsAcl.attendancePolicies.trackingMethod.getAll,
      },

      {
        path: ATTENDANCE_METHOD + "/" + ATTENDANCE_METHOD_FORM,
        component: lazy(
          () => import("@pages/Settings/AttendanceManagement/AttendanceMethods/Steps/AttendanceMethodFormLayout")
        ),
        meta: hrmSettingsAcl.attendancePolicies.parent,
        children: [
          {
            path: ATTENDANCE_METHOD_GENERAL_FROM,
            component: lazy(() => import("@pages/Settings/AttendanceManagement/AttendanceMethods/Steps/GeneralForm")),
            meta: [
              hrmSettingsAcl.attendancePolicies.trackingMethod.update,
              hrmSettingsAcl.attendancePolicies.trackingMethod.create,
            ],
          },
          {
            path: ATTENDANCE_METHOD_EMPLOYEE_FROM,
            component: lazy(
              () => import("@pages/Settings/AttendanceManagement/AttendanceMethods/Steps/AssignEmployeeForm")
            ),
            meta: [
              hrmSettingsAcl.attendancePolicies.trackingMethod.update,
              hrmSettingsAcl.attendancePolicies.trackingMethod.create,
            ],
          },
        ],
      },
      {
        path: OVERTIME_POLICY,
        component: lazy(() => import("@pages/Settings/AttendanceManagement/OverTimePolicy/OverTimePolicy")),
        meta: hrmSettingsAcl.attendancePolicies.overtimePolicies.getAll,
      },
    ],
  },
];
