import gameOverAudio from "@assets/audio/game-over.mp3";
import windBlow from "@assets/audio/wind.wav";
import Button from "@components/Button/Button";
import { ENV } from "@config/ENV.config";
import { SessionStorageService } from "@services/utils/localsStorage.service";
import React, { ErrorInfo, ReactNode } from "react";
import { cacheClear, delay } from "utils";
import "./ErrorStyle.css";

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: Boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  audio = new Audio(gameOverAudio);
  // TODO:: debugging purpose only
  zero = SessionStorageService.get("zeroDay");

  componentDidMount(): void {
    this.audio.muted = true;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    // this.audio.muted = false;
    // this.audio.play();

    this.setState({
      error: error,
      errorInfo: info,
    });

    if (ENV.env === "development" || this.zero == "0000") logErrorToMyService(error, info.componentStack);
    // You can also log error messages to an error reporting service here
  }

  componentWillUnmount(): void {
    SessionStorageService.delete("zeroDay");
  }

  hardReload = async () => {
    const audio = new Audio(windBlow);
    audio.play();
    await cacheClear();
    await delay(4500);

    location.reload();
  };

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="">
                <div className="text-center">
                  <h2 className="mb-3 text-center text-3xl">Hmm... Don't worry we are working on it 😎</h2>
                  <div className="four_zero_four_bg"></div>

                  <div className="content_box_404">
                    {ENV.env === "development" || this.zero == "0000" ? (
                      <p className="text-3xl font-semibold text-danger-500">{error?.message}</p>
                    ) : null}

                    <div className="flex justify-center mt-3">
                      <Button onClick={() => this.hardReload()} className="ml-3" color="success" variant="shade">
                        Super Hard Reload
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    // Normally, just render children
    return children;
  }
}

// Custom function to handle error logging
const logErrorToMyService = (error: Error, componentStack: string | null) => {
  // Custom implementation for error logging
  console.error("Error occurred:", error);
  console.error("Component Stack:", componentStack);
};

export default ErrorBoundary;
