import { default as blob1 } from "@assets/images/organization-notfound-blob-1.png";
import { default as blob2 } from "@assets/images/organization-notfound-blob-2.png";
import Logo from "@assets/images/rysenova-logo-v7_720.png";
import FourZeroFiveImg from "@assets/images/FourZeroFive.png";

const InactiveOrg = () => {
  return (
    <div className="w-full lg:py-24 lg:px-20 md:py-14 md:px-14 sm:py-8 sm:px-8 xs:py-6 xs:px-6 h-auto lg:h-[100vh] sm:h-auto md:h-[100vh] relative">
      <img className="absolute -z-[99] top-2 lg:top-10 left-0 mb-20" src={blob1} alt="" />
      <img className="absolute bottom-0 right-0 -z-[99]" src={blob2} alt="" />

      <div className="w-full">
        <div className="mb-20">
          <img src={Logo} alt="" className="lg:w-[273px] md:w-[240px] lg:h-10 h-auto sm:w-[220px] xs:w-[200px]" />
        </div>
        <div className="lg:mt-36 md:mt-32 sm:mt-6 xs:mt-6 flex flex-col lg:flex-row md:flex-row w-full h-full lg:gap-10  lg:items-center lg:justify-between">
          <div className="lg:pl-10 xs:pl-6 basis-1/2">
            <h2 className="text-neutral-800 uppercase tracking-[5.76px] md:text-xl sm:text-xl lg:text-6xl font-semibold mb-4 ">
              Access Denied!
            </h2>
            <p className="text-neutral-700 font-inter text-base mb-4 xl:w-[60%] lg:w-[80%] md:w-full">
              Your organization is currently inactive, restricting your access at this time. If you have any questions
              or need assistance, feel free to reach out to the Rysenova support team—we’re here to help!
            </p>
          </div>
          <div className=" w-full basis-1/2 mt-6">
            <img className="lg:w-[433px] xs:w-[390px] ml-auto " src={FourZeroFiveImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InactiveOrg;
