import Icon from "@components/Icon/Icon";
import Image from "@components/Image/Image";
import { LayoutContext } from "@layouts/AuthLayout";
import { AuthService } from "@services/apis/Auth.service";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { ToastService } from "@services/utils/toastr.service";
import { useConfirmAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Button from "@components/Button/Button";
import EmployeeCard from "@components/Card/EmployeeCard";
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from "@components/Popover/Popover";
import { INotification } from "@interfaces/notification.interface";
import Notifications from "@pages/Notifications/Notifications";
import { NotificationService } from "@services/apis/Notification.service";
import { useSkeleton } from "@services/utils/skeleton.service";
import { NOTIFICATIONS, ORGANIZATION_CALENDER } from "router/path-name.route";
import { isArray } from "utils";
import { Constant } from "utils/constant";
import { useProfileAvatar } from "utils/hook.utils";

const Header = ({ show }: any) => {
  const { logout, isAuthenticated } = useAuth();
  const { showAlert } = useConfirmAlert();
  const { showSidebar, setShowSidebar } = useContext(LayoutContext);
  const [popoverShow, setPopoverShow] = useState(false);
  const [notification, setNotification] = useState<INotification[]>([]);

  const userDate = LocalStorageService.get("userData");

  const [selectedLng, setSelectedLng] = useState<string>("");

  const { userData } = useAuth();

  const [isIdle, setIsIdle] = useState(false);
  const [notificationPopover, setNotificationPopover] = useState(false);
  const [filter, setFilter] = useState<"all" | "unread">("all");

  const { path, avatarClass } = useProfileAvatar(userData);
  const { startSkeleton, stopSkeleton, onStartSkeleton } = useSkeleton(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { i18n } = useTranslation();

  const timerRef = useRef(null);
  const intervalRef = useRef(null);
  const timeout = 60000; // 1 min
  const intervalTime = 20000 * 6; // 2 minutes

  const unreadNotification: number = notification?.filter((item) => !item.read_at)?.length;

  const filterRef = useRef(filter); // useRef to hold filter state

  useEffect(() => {
    if (selectedLng) {
      i18n.changeLanguage(selectedLng);
      LocalStorageService.set("local_i18nextLng", selectedLng);
      window.location.reload();
    }
  }, [selectedLng, isAuthenticated]);

  useEffect(() => {
    if (userDate.is_employee) {
      onStartSkeleton();
      filterRef.current = filter; // Update ref when filter state changes
      getAllNotification();
    }
  }, [filter]);

  useEffect(() => {
    setNotificationPopover(false);
  }, [location]);

  useEffect(() => {
    if (userDate.is_employee) {
      const handleEvents = () => resetTimer();

      window.addEventListener("mousemove", handleEvents);
      window.addEventListener("keydown", handleEvents);

      resetTimer(); // Initialize the timer

      return () => {
        window.removeEventListener("mousemove", handleEvents);
        window.removeEventListener("keydown", handleEvents);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        stopInterval();
      };
    }
  }, [timeout]);

  const onIdle = () => {
    // console.log("User is idle.");
    // You can add any action here, like showing a logout modal or logging the user out.
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsIdle(false);
    startInterval();
    timerRef.current = setTimeout(() => {
      setIsIdle(true);
      onIdle();
      stopInterval();
    }, timeout);
  };

  const startInterval = () => {
    if (intervalRef.current) return; // Prevent multiple intervals
    intervalRef.current = setInterval(() => {
      getAllNotification();

      // You can place your recurring action here.
    }, intervalTime); // Interval runs every 1 second
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const onLogout = async () => {
    showAlert({
      title: t("confirm_logout"),
      confirmMessage: t(`are_you_sure_you_want_to_logout`),
      confirmBtnText: t("yes"),
      alertType: "logout",
      async onConfirm() {
        await AuthService.logout()

          .catch((err) => {
            ToastService.error(err.message);
          })
          .finally(async () => {
            await logout();
          });
      },
    });
  };

  const getAllNotification = () => {
    NotificationService.getAll({ page: 1, limit: 20, filters: { status: filterRef.current } })
      .then(({ payload, meta }) => {
        setNotification(payload);
      })
      .finally(() => stopSkeleton());
  };

  return (
    <div className={`header z-50 w-full h-full ${show ? "" : "hidden"}`}>
      <div className="header-wrapper flex w-full h-full xs:px-2 md:px-6 py-3 bg-white">
        {/* TODO:: For responsive reference */}
        <div className="header-left xs:ml-2 h-full md:w-[150px] lg:hidden flex items-center">
          <button onClick={() => setShowSidebar(!showSidebar)} className="border-none mr-5 lg:hidden sm:block">
            <Icon
              className={` animate-bounce-in ${showSidebar ? "text-danger-500" : "text-primary-500"}`}
              name={showSidebar ? "close" : "menu"}
              variant="outlined"
            />
          </button>
        </div>
        {/*<div className="w-52 px-4 py-2 lg:flex xs:hidden sm:hidden flex items-center mr-auto shadow rounded-md">
           <Input
            label="search"
            placeholder="Search Here"
          /> 
          <Icon name="search" className="text-neutral-300 text-base" />
          <span className="ml-3 text-neutral-300 text-sm font-medium">{t("search")}</span>
        </div>*/}

        <div className="header-right basis-full relative  flex justify-end items-center">
          <Link
            to={`${ORGANIZATION_CALENDER}`}
            className=" py-2 px-3 md:mr-4 mr-2 flex  relative text-neutral-400 border border-neutral-50 space-x-3 items-center rounded-lg cursor-pointer"
            data-test-id="calendar-header"
          >
            <Icon name="calendar_today" variant="outlined" className="text-base text-primary-500" />
            <p className="text-xs hidden md:block text-neutral-600">Calendar</p>
          </Link>
          {/* //  select language part */}
          <div
            data-test-id="localization-header"
            className=" border  flex border-neutral-50 md:px-3 px-2 py-[2px] md:mr-4 mr-3 bg-white rounded-lg shadow-sm  items-center cursor-pointer"
          >
            <Icon className="text-base  text-neutral-500" name="language" />

            <select
              className={`border-0 outline-none  text-secondary-text text-xs p-2 text-neutral-600  bg-white cursor-pointer`}
              // className="border-0 outline-none  text-secondary-text text-xs p-2 bg-white"
              onChange={(e) => {
                setSelectedLng(e?.target?.value);
                // window.location.reload();
              }}
              defaultValue={LocalStorageService.get("local_i18nextLng") || localStorage.getItem("i18nextLng")}
            >
              <option className="p-1 text-neutral-500" value="en">
                English
              </option>
              <option className="p-1 text-neutral-500" value="bn">
                বাংলা
              </option>
              <option className="p-1 text-neutral-500" value="ar">
                عربي
              </option>
            </select>
          </div>

          {/* notification */}
          <Popover
            dataTestId="header-notification-bell-popover"
            open={notificationPopover}
            onOpenChange={() => setNotificationPopover(false)}
          >
            <PopoverTrigger>
              <div
                onClick={() => userDate.is_employee && setNotificationPopover(!notificationPopover)}
                className={`px-2 py-1 flex items-end  text-neutral-400 border border-neutral-50 rounded-lg  ${
                  userDate.is_employee ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              >
                <div className="relative">
                  <Icon
                    name="notifications"
                    variant="outlined"
                    className={`text-xl  ${userDate.is_employee ? "text-primary-500" : "text-neutral-300"}`}
                  />
                  {isArray(notification) && unreadNotification ? (
                    <span className="absolute w-3 h-3 left-[10px] bg-danger-500 text-white text-xxs text-center rounded-full">
                      {unreadNotification}
                    </span>
                  ) : null}
                </div>
              </div>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody className="flex flex-col justify-between h-[600px] py-1 bg-white shadow-md rounded-md overflow-y-scroll w-auto md:mx-0 mx-4 mt-3">
                <Notifications
                  notification={notification}
                  setNotification={setNotification}
                  filter={filter}
                  setFilter={setFilter}
                  startSkeleton={startSkeleton}
                  stopSkeleton={stopSkeleton}
                />
                <Link
                  to={NOTIFICATIONS}
                  className={`h-[20px] mx-6 pt-3 pb-6 text-sm font-semibold border-t-2 border-neutral-50 ${
                    isArray(notification) && filter === "all"
                      ? "text-primary-500"
                      : "pointer-events-none text-neutral-300"
                  }`}
                >
                  See All Notifications
                </Link>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          {/* user profile */}
          <div className="lg:ml-4 sm:px-4 px-2 lg:px-0 md:mt-0 mt-2">
            <Popover dataTestId="header-profile-popover" open={popoverShow} onOpenChange={setPopoverShow}>
              <PopoverTrigger>
                <div
                  onClick={() => setPopoverShow(!popoverShow)}
                  className="flex items-center justify-start space-x-3 md:pr-3"
                >
                  <EmployeeCard noPadding isDesignationTrim employeeData={userData} nameClassName="md:block hidden" />
                  <Icon
                    className="text-lg cursor-pointer text-neutral-400 md:block hidden"
                    name="expand_more"
                    variant="outlined"
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <div className="flex flex-col p-10 rounded-xl shadow-lg ">
                    <div
                      className={`${avatarClass} flex justify-center items-center -top-7 mx-auto z-10 rounded-[10px] w-[100px] h-[100px]`}
                    >
                      <div className="relative">
                        <Image src={path} className="w-[100px] h-[100px]" noBorder />
                        <Icon
                          className="hidden  items-center justify-center absolute w-[26px] text-lg text-neutral-400 h-[26px] rounded-full bg-white shadow-md -right-[8px] -bottom-[6px] cursor-pointer"
                          name="edit"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col justify-center gap-2 items-center mt-3">
                      <h3 className="font-inter text-base font-bold text-neutral-700 mb-0">{userData?.name}</h3>
                      <h3 className="font-inter text-xs font-bold text-neutral-500 ">
                        {userData?.designation || Constant?.NO_DATA}
                      </h3>
                      <div className=" text-center">
                        <h4 className="font-inter text-xs text-neutral-500 font-normal mb- leading-none">
                          {userData?.email || ""}
                        </h4>
                      </div>
                      <div className="flex mt-4">
                        <Button
                          onClick={() => {
                            navigate("settings/profile/general");
                            setPopoverShow(false);
                          }}
                          variant="outlined"
                          size="sm"
                          className="mr-4"
                          color="dark"
                          dataTestId="header-profile-btn"
                        >
                          <div className="flex item-center">
                            <Icon className="text-sm leading-tight text-neutral-500 mr-2" name="person" />
                            <span className="text-sm leading-tight">{t("profile")}</span>
                          </div>
                        </Button>
                        <Button
                          onClick={() => onLogout()}
                          variant="outlined"
                          size="sm"
                          color="dark"
                          dataTestId="header-logout-btn"
                        >
                          <div className="flex item-center">
                            <Icon className="text-sm leading-tight text-neutral-500 mr-2" name="logout" />
                            <span className="text-sm leading-tight">{t("logout")}</span>
                          </div>
                        </Button>
                      </div>

                      <div className="text-[10px] text-neutral-400 flex items-center space-x-4 justify-center mt-2">
                        <span>{t("privacy_policy")}</span>
                        <div className="w-[4px] h-[4px] bg-neutral-400 rounded-full">.</div>
                        <span>{t("terms_of_service")}</span>
                      </div>
                    </div>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
