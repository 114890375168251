import { fullMonth } from "@data/common.data";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { endOfWeek, startOfWeek } from "date-fns";
import { t } from "i18next";
import { formatDateToYYYYMMDD, isValidDate } from ".";
import { Constant } from "./constant";
import { systemDate, systemTime } from "./system.utils";

export const isDateExpired = (date: Date) => {
  const cDate = new Date();
  const oDate = new Date(date);
  return cDate > oDate;
};

export const splitDate = (dateString: string | number | Date = "") => {
  const orderDate = new Date(dateString);
  const fullMonth = orderDate.toLocaleString("en-US", { month: "long" });
  const orderDateStr = orderDate.toDateString().split(" ");
  const orderTimeStr = orderDate.toLocaleTimeString().split(":");

  return {
    dd: orderDate.getDate(),
    DD: orderDateStr[0],
    mm: orderDate.getMonth(),
    MM: orderDateStr[1],
    MMMM: fullMonth,
    date: orderDateStr[2],
    yyyy: orderDateStr[3],
    hour: orderTimeStr[0],
    minute: orderTimeStr[1],
    second: orderTimeStr[2]?.split(" ")?.[0] || "",
    ampm: orderTimeStr[2]?.split(" ")?.[1] || "",
  };
};

export const dateFormate = (dateString: string | number | Date, formate?: string) => {
  const dateTime = splitDate(dateString);
  const date = new Date(dateString);
  try {
    if (formate === "iso") {
      return date.toISOString().split("T")[0];
    }
    if (formate === "time") {
      return date.toLocaleTimeString("en-GB");
    }
    return ` ${dateTime.date} ${dateTime.MM}, ${dateTime.yyyy} at
	${dateTime.hour}:${dateTime.minute} ${dateTime.ampm}`;
  } catch (err) {
    return "N/A";
  }
};

export const onlyDateFormate = (dateString: string | number | Date, formate?: string) => {
  const dateTime = splitDate(dateString);
  const date = new Date(dateString);
  try {
    if (formate === "iso") {
      return date.toISOString().split("T")[0];
    }
    if (formate === "time") {
      return date.toLocaleTimeString("en-GB");
    }
    // return ` ${dateTime.date} ${t(dateTime.MM.toLocaleLowerCase())}, ${
    return ` ${dateTime.date} ${dateTime.MM}, ${dateTime.yyyy} `;
  } catch (err) {
    return t("n/a");
  }
};

export const generateDateFormat = (date: string | number, format: string) => {
  const dateTime = splitDate(date);

  const dd = dateTime?.dd <= 9 ? "0" + dateTime.dd : dateTime.dd;
  // TODO:: if any issue relevant of month  please this line
  const month = dateTime.mm + 1;
  const mm = month <= 9 ? "0" + month : month;

  return (
    format
      .replace("dd", dd || "0")
      .replace("DD", dateTime?.DD || "0")
      .replace("date", dateTime?.date || "0")
      // TODO:: temporary handle month number
      .replace("mm", mm || "0")
      .replace("MMMM", dateTime?.MMMM)
      .replace("MM", dateTime?.MM || "0")
      .replace("yyyy", dateTime?.yyyy || "0")
      .replace("YYYY", dateTime?.yyyy || "0")
      .replace("hour", dateTime?.hour || "0")
      .replace("minute", dateTime?.minute || "0")
      .replace("second", dateTime?.second || "0")
      .replace("ampm", dateTime?.ampm || "N/A")
  );
};

export const addHours = (numOfHours: number, date: any = null) => {
  date = date ? new Date(date) : new Date();
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};

export const offsetTimeForTimezone = (date: any) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

export const formatDateForCard = (date: any): string => {
  const [dateString] = date.split(" ");
  const dateObj = new Date(dateString);
  const formatted_date = dateObj.getDate() + " " + fullMonth[dateObj.getMonth()] + " " + dateObj.getFullYear();

  return formatted_date;
};

export const timeDiff = (date1: string, date2: string): any => {
  const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
  const intervalNames = [" year", " month", " week", " day", " hour", " minute", " second"];

  if (!isValidDate(date1)) date1 = combineDateAndTime(date1);
  if (!isValidDate(date2)) date2 = combineDateAndTime(date2);

  const diff = Math.abs(new Date(date2).getTime() - new Date(date1).getTime()) / 1000;
  const index = timeIntervals.findIndex((i) => diff / i >= 1);
  const n = Math.floor(diff / timeIntervals[index]);
  const interval = intervalNames[index];

  return [n, interval];
};

export const localize = (value: number, str: string): string => {
  if (value != 1) str += "s";
  return `${value} ${str}`;
};

export const formattedTimeDiff = (date1: string, date2: string): string => {
  if (!String(date1) && !String(date2)) return "0";
  if (date1 === date2) return "1 day";
  const [diff1, diff2] = timeDiff(date1, date2);
  return localize(diff1, diff2);
};

export const combineDateAndTime: any = (time: string) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Jan is 0, dec is 11
  const day = date.getDate();
  const dateString = "" + year + "-" + month + "-" + day;
  const combined = new Date(dateString + " " + time);
  return combined;
};

export const onlyDate = (date: string | Date) => {
  const dateString = splitDate(date);

  let day: any = +dateString.date;
  let month: any = +dateString.mm + 1;

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;
  return `${dateString.yyyy}-${month}-${day}`;
};

// extract day from date
export const extractDayFromDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  return day;
};

// get date range between dates
export const getDatesInRange = (startDate: string, endDate: string, type: string = "Week") => {
  if (!(startDate && endDate)) return;
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (type === "Month") end.setDate(end.getDate() + 1);
  const dates = [];
  const currentDate = new Date(start);
  while (currentDate <= end) {
    dates.push(formatDateToYYYYMMDD(new Date(currentDate))); // Clone the date to avoid mutation.
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

// extract month from  date
export function getMonthFromDate(dateString: any) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  return month;
}

export function calculateHour(inputTime: string, returnZero: boolean = true) {
  if (!inputTime) return null;
  const [hours, minutes, seconds] = inputTime.split(":").map(Number);

  if (minutes === 0 && seconds === 0) {
    return hours != 0 ? `${hours} ${"h"}` : returnZero ? `${hours} ${"h"}` : Constant.NO_DATA;
  } else if (hours === 0) {
    return `${minutes} ${"m"}`;
  } else {
    return `${hours} ${"h"} ${minutes} ${"m"}`;
  }
}

export function calculateTimeDifference(inputTime: string) {
  const [hours, minutes] = inputTime.split(":").map(Number);

  if (isNaN(hours) || isNaN(minutes)) {
    return "Invalid input";
  }

  const sign = inputTime.includes("-") ? "-" : "";

  if (hours === 0 && minutes === 0) return `0${t("m")}`;

  if (hours === 0) return `${sign}${minutes}${t("m")}`;

  return `${hours} ${t("h")} ${minutes} ${t("m")}`;
}

export function startsWithMinusOrPlus(inputString: string) {
  return inputString?.startsWith("-") || inputString?.startsWith("+");
}

// export function rangeWeek(dateStr?: Date | any) {
//   if (!dateStr) dateStr = new Date().getTime();
//   let dt = new Date(dateStr);
//   dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
//   dt = new Date(
//     dt.getTime() -
//       (dt.getDay() > 0
//         ? (dt.getDay() - 1) * 1000 * 60 * 60 * 24
//         : 6 * 1000 * 60 * 60 * 24)
//   );
//   return {
//     start: dt,
//     end: new Date(dt.getTime() + 1000 * 60 * 60 * 24 * 7 - 1),
//   };
// }

// getMonth()	Get month as a number (0-11)
// getDate()	Get day as a number (1-31)
// getDay()	Get weekday as a number (0-6)
// getHours()	Get hour (0-23)

export function rangeWeek(date?: Date): { start: Date; end: Date } {
  const { week_start_day: { value: weekStartsOn = 0 } = {} } = LocalStorageService.get("systemSettings");

  const currentDate = date || new Date();
  // const startOfWeek = new Date(currentDate);
  // startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

  // const endOfWeek = new Date(startOfWeek);
  // endOfWeek.setDate(startOfWeek.getDate() + 6);

  return {
    start: startOfWeek(currentDate, { weekStartsOn: weekStartsOn }),
    end: endOfWeek(currentDate, { weekStartsOn: weekStartsOn }),
  };
}
// export function rangeMonth(): { start: Date; end: Date } {
//   const startOfMonth = new Date();
//   startOfMonth.setDate(1);
//   const endOfMonth = new Date(
//     startOfMonth.getFullYear(),
//     startOfMonth.getMonth() + 1,
//     0
//   );

//   return {
//     start: startOfMonth,
//     end: endOfMonth,
//   };
// }

// export function rangeMonth(): { start: Date; end: Date } {
//   const currentDate = new Date();
//   const startOfMonth = new Date(
//     currentDate.getFullYear(),
//     currentDate.getMonth(),
//     1
//   );
//   const endOfMonth = new Date(
//     currentDate.getFullYear(),
//     currentDate.getMonth() + 1,
//     0
//   );
//   const endOfMonthAdjusted =
//     currentDate.getMonth() === startOfMonth.getMonth()
//       ? currentDate
//       : endOfMonth;

//   return {
//     start: startOfMonth,
//     end: endOfMonthAdjusted,
//   };
// }
export function rangeMonth(includeCurrentDate: boolean = true): {
  start: Date;
  end: Date;
} {
  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const endOfMonthAdjusted = includeCurrentDate ? currentDate : endOfMonth;

  return {
    start: startOfMonth,
    end: endOfMonthAdjusted,
  };
}

// get the specific day of week
export const getDayOfWeek = (date: Date): string => {
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};
// get month index
export const getMonthIndexFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const monthIndex = date.getMonth();

  return monthIndex;
};
// get Year From Date
export const getYearFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();

  return year;
};

// get formatted date
export const getFormattedDate = (dateString: Date) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// date format for schedule overview
export const dateFormatDD = (inputDate: Date) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const parsedDate = new Date(inputDate);

  const day = days[parsedDate.getUTCDay()];
  const month = months[parsedDate.getUTCMonth()];
  const date = parsedDate.getUTCDate();

  return `${t(day.toLowerCase())}, ${t(month.toLowerCase())} ${date}`;
};

export const newDate = (dateTime?: any): any => {
  // const timeZone = JSON.parse(localStorage.getItem("systemSettings") || "{}")
  //   ?.timezone?.value;

  const timeZone = LocalStorageService.get("systemSettings")?.timezone?.value;

  const newD = dateTime ? new Date(dateTime) : new Date();

  const localTime = newD.toLocaleTimeString("en-US", {
    timeZone: timeZone,
    hour12: false,
  });

  const timeSplit = localTime.split(":");
  // console.log(localTime, timeSplit);

  newD.setHours(Number(timeSplit[0]));
  newD.setMinutes(Number(timeSplit[1]));

  return newD;
};

// export const testDate = () => {
//   const timeZone = LocalStorageService.get("systemSettings")?.timezone?.value;

//   const now = new Date(); // Current time in user's local time zone
//   const targetDate = new Date(now.toLocaleString("en-US", { timeZone })); // Convert to specified time zone

//   const options = {
//     day: "numeric", // Display day of the week (Tue)
//     month: "short",
//     year: "numeric",
//     hour: "numeric",
//     minute: "numeric",
//     second: "numeric",
//     hour12: true, // Use 12-hour format with AM/PM
//     timeZoneName: "short", // Display short time zone name
//   };

//   // Format hours with padding and AM/PM based on chosen time zone
//   const formattedHours = targetDate.toLocaleString("en-US", {
//     hour: "numeric",
//     hour12: true,
//   });

//   // Extract time zone abbreviation
//   const timeZoneAbbreviation = targetDate.toLocaleString("en-US", {
//     timeZoneName: "short",
//   });

//   // Extract time zone offset using the correct `timeZone` option
//   const timeZoneOffset = targetDate.toLocaleString("en-US", { timeZone });

//   // Combine all elements into the desired format
//   const formattedTime = `Tue ${targetDate.toLocaleString("en-US", {
//     month: "short",
//   })} ${targetDate.toLocaleString("en-US", {
//     day: "numeric",
//   })} ${targetDate.getFullYear()} ${formattedHours} ${timeZoneOffset} ( ${timeZoneAbbreviation} )`;

//   return formattedTime;
// };
const attendanceStartDate = () => {
  try {
    const { attendance_pull_start_date } = LocalStorageService.get("systemSettings");
    return attendance_pull_start_date;
  } catch (er) {
    return null;
  }
};

export const attendancePullStartDate = attendanceStartDate();

export const calculateRuleTimes = (timeValue: string | Date | any, minutesToSubtract: number, addMin = false) => {
  const dateTime = newDate(timeValue instanceof Date ? timeValue : combineDateAndTime(timeValue));
  if (!dateTime) return "-";

  if (addMin) {
    dateTime.setMinutes(dateTime.getMinutes() + minutesToSubtract);
    return systemTime(dateTime.toLocaleTimeString());
  }

  dateTime.setMinutes(dateTime.getMinutes() - minutesToSubtract);
  return systemTime(dateTime.toLocaleTimeString());

  // const timeString = formatTime(timeValue);

  // Split the time string into hours and minutes
  const [hoursStr, minutesStr] = timeValue.split(":");
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);

  // Convert the time to minutes
  const totalMinutes = hours * 60 + minutes;

  // Adjust whether to add or subtract minutes
  const resultMinutes = addMin ? totalMinutes + minutesToSubtract : totalMinutes - minutesToSubtract;

  // Adjust for negative minutes
  let resultHours = Math.floor(resultMinutes / 60);
  let resultMinutesRemainder = resultMinutes % 60;

  // Adjust if resultMinutes is negative
  if (resultMinutes < 0) {
    resultMinutesRemainder = 60 + resultMinutesRemainder;
    resultHours--;
  }

  // Adjust for negative hours
  if (resultHours < 0) {
    resultHours = 24 + resultHours;
  }

  // Determine if it's AM or PM for the result
  const resultIsAM = resultHours < 12;

  // Format the result
  const formattedHours = resultHours % 12 === 0 ? 12 : resultHours % 12;
  const formattedMinutes = resultMinutesRemainder < 10 ? `0${resultMinutesRemainder}` : resultMinutesRemainder;

  const ampm = resultIsAM ? "AM" : "PM";

  const finalValue = formattedHours && formattedMinutes ? `${formattedHours}:${formattedMinutes} ${ampm}` : "--";

  // Return the formatted result
  return finalValue;
};

export const UTC2GMT = (iso: string) => {
  const date = new Date(iso);

  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export function isTodayOrPastDate(inputDate) {
  // Get today's date
  const today = new Date();

  // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
  today.setHours(0, 0, 0, 0);

  // Convert inputDate to a Date object if it's not already
  if (!(inputDate instanceof Date)) {
    inputDate = new Date(inputDate);
  }

  // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
  inputDate.setHours(0, 0, 0, 0);

  // Compare inputDate with today's date
  return inputDate <= today;
}

export const handleDateReset = (inputDate: Date | string) => {
  if (inputDate instanceof Date) {
    return new Date(inputDate.setHours(0, 0, 0, 0));
  } else {
    return new Date(new Date(inputDate).setHours(0, 0, 0, 0));
  }
};

export const FirstnLastDayOfMonth = (date: string, returnDate?: "firstDate" | "lastDate") => {
  if (!date)
    return {
      firstDay: "",
      lastDay: "",
    };

  const currentDate = new Date(date);
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toString();
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toString();

  return {
    firstDay: firstDay,
    lastDay: lastDay,
  };
};

export const dateRangeShowingFormat = (startDate, endDate) => {
  let start, end;

  if (isValidDate(startDate)) start = systemDate(startDate);
  if (isValidDate(endDate)) end = systemDate(endDate);

  return end ? `${start} - ${end}` : start ? start : Constant.NO_DATA;
};
