export const encode = (filters) => {
  try {
    const jsonString = JSON.stringify(filters);
    const encoder = new TextEncoder();
    const encoded = encoder.encode(jsonString);

    // Base64 encode and then replace characters for URL safety
    return btoa(String.fromCharCode(...encoded))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, ""); // Remove padding

    // previous code
    // const jsonString = JSON.stringify(filters); // Convert filters to JSON string
    // const compressedString = jsonString.replace(/\s+/g, ""); // Remove unnecessary whitespace

    // // Custom obfuscation: Shift each character's code and map to base-36
    // let encoded = "";
    // for (let i = 0; i < compressedString.length; i++) {
    //   encoded += (compressedString.charCodeAt(i) + 3).toString(36); // Shift and encode
    // }

    // return encoded;
  } catch (error) {
    console.error("Failed to encode filters:", error);
    return null;
  }
};

export const decode = (encodedFilters) => {
  try {
    // Reverse the URL-safe Base64 encoding
    const decodedString = encodedFilters.replace(/-/g, "+").replace(/_/g, "/");

    const decoded = Uint8Array.from(atob(decodedString), (c) => c.charCodeAt(0));
    const decoder = new TextDecoder();
    const jsonString = decoder.decode(decoded);

    return JSON.parse(jsonString);

    // previous code
    // let jsonString = "";
    // // Reverse the obfuscation: Decode base-36 and shift back character codes
    // for (let i = 0; i < encodedFilters.length; i += 2) {
    //   const charCode = parseInt(encodedFilters.substr(i, 2), 36) - 3;
    //   jsonString += String.fromCharCode(charCode);
    // }

    // return JSON.parse(jsonString); // Parse the JSON string back to an object
  } catch (error) {
    console.error("Failed to decode filters:", error);
    return null;
  }
};
