import { apiIns } from "@config/api.config";
import { IQueryParams } from "@interfaces/common.interface";
import { isObject, queryStringMapper } from "utils";

export const ReportService = {
  getFavorite: async (): Promise<any> => await apiIns.get(`report/my-favorites`),

  createFavoriteReport: async (payload: any): Promise<any> => await apiIns.post("report/toggle-favorite", payload),

  getAttendanceReport: async (
    searchQuery: IQueryParams,
    filterQueries: any,
    from_date: string,
    to_date: string
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendances${queryStringMapper(searchQuery)}
      ${from_date ? "&from_date=" + from_date : ""}
      ${to_date ? "&to_date=" + to_date : ""}
      ${isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""}
      `
    ),

  getAttendanceLateEarly: async (
    searchQuery: IQueryParams,
    filterQueries: any,
    from_date: string,
    to_date: string
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendances/late-early${queryStringMapper(searchQuery)}
      ${from_date ? "&from_date=" + from_date : ""}
      ${to_date ? "&to_date=" + to_date : ""}
      ${isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""}
      `
    ),

  getEmployeePerformance: async (id: string, from_date: string, to_date: string): Promise<any> =>
    await apiIns.get(
      `reports/attendances/${id}?${from_date ? "from_date=" + from_date : ""}
              ${from_date ? "&to_date=" + to_date : ""}
             
              `
    ),

  getEmployees: async (searchQuery: IQueryParams, filterQueries: any): Promise<any> =>
    await apiIns.get(
      `reports/employees${queryStringMapper(searchQuery)}${
        isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""
      }`
    ),

  getEmployeeSummery: async (searchQuery: IQueryParams, filterQueries: any): Promise<any> =>
    await apiIns.get(
      `reports/employees/summary${queryStringMapper(searchQuery)}${
        isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""
      }`
    ),

  getLeaveBalanceReport: async (searchQuery: IQueryParams): Promise<any> =>
    await apiIns.get(`reports/leave-balances${queryStringMapper(searchQuery)}`),

  getSchedules: async (searchQuery: IQueryParams, from_date: string, to_date: string, filters: any): Promise<any> =>
    await apiIns.get(
      `reports/schedules${queryStringMapper(searchQuery)}${from_date ? "from_date=" + from_date : ""}${
        to_date ? "&to_date=" + to_date : ""
      }
      ${isObject(filters) ? "&filters=" + JSON.stringify(filters) : ""}
        `
    ),

  getLeaveApplicationStatus: async (
    searchQuery: IQueryParams,

    filters: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/leave-applications/status-report${queryStringMapper(searchQuery)}${
        isObject(filters) ? "&filters=" + JSON.stringify(filters) : ""
      }`
    ),

  getReportOptions: async (): Promise<any> => await apiIns.get(`reports/options`),
};

export const ReportAttendanceService = {
  getDailyAttendanceReport: async (searchQuery: IQueryParams, filterQueries: any, date: string): Promise<any> =>
    await apiIns.get(
      `reports/daily-attendances${queryStringMapper(searchQuery)}
      ${date ? "&date=" + date : ""}
     
      ${isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""}
      `
    ),
  getAttendanceSummery: async (
    searchQuery: IQueryParams,
    from_date: string,
    to_date: string,
    filterQueries: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendance-summary${queryStringMapper(searchQuery)}
      ${from_date ? "&from_date=" + from_date : ""}${to_date ? "&to_date=" + to_date : ""}

      ${isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""}
      `
    ),
};

// payroll

export const ReportSalarySheetService = {
  getAllEmployeesByID: async (id: string, queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`payroll-service/reports/salary-sheets/` + id + queryStringMapper(queryParams)),

  getReportAllSalarySheet: async (id: string, queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`payroll-service/reports/salary-sheets/export/` + id + queryStringMapper(queryParams)),

  getPaymentChannel: async (id: string): Promise<any> =>
    await apiIns.get(`payroll-service/periodic-payrolls/payment-channels/` + id),

  getDisburseGroup: async (payrollId: string, payment_channel_id: string): Promise<any> =>
    await apiIns.get(`payroll-service/disbursement-groups/payment-channels/${payrollId}/${payment_channel_id}`),
};

export const ReportPayrollSummeryService = {
  getDetailsByID: async (id: string, queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`payroll-service/reports/salary-sheets/summary/${id}` + queryStringMapper(queryParams)),
};

export const ReportPayScheduleService = {
  getDetailsByID: async (id: string, queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`payroll-service/periodic-payrolls/get-report/` + id),
};
export const PayrollIndividualEmployService = {
  getEmployees: async (searchQuery: IQueryParams, filterQueries: any): Promise<any> =>
    await apiIns.get(
      `payroll-service/employee-salaries${queryStringMapper(searchQuery)}${
        isObject(filterQueries) ? "&filters=" + JSON.stringify(filterQueries) : ""
      }`
    ),
  getDetailsByID: async (id: string, queryParams?: IQueryParams): Promise<any> => {
    const fullUrl =
      `payroll-service/employee-salaries/get-report-by-
employee/` +
      id +
      queryStringMapper(queryParams);
    return await apiIns.get(fullUrl);
  },
};
