import commonGenderImg from "@assets/images/commonGender.png";
import femaleAvatarImg1 from "@assets/images/femaleAvatar1.png";
import femaleAvatarImg2 from "@assets/images/femaleAvatar2.png";
import femaleAvatarImg3 from "@assets/images/femaleAvatar3.png";
import maleAvatarImg1 from "@assets/images/maleAvatar1.png";
import maleAvatarImg2 from "@assets/images/maleAvatar2.png";
import maleAvatarImg3 from "@assets/images/maleAvatar3.png";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { addSearchParams, searchParamsToObject } from "utils";
import { newDate } from "./date.utils";
import { encode } from "./hash";
import { infiniteScrollDataLimit } from "./index";

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const useDebounce = (value: any, delay: number = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

export const useProfileAvatar = (user: any = {}, index?: number) => {
  const { file = "", phone = "", gender = "" } = user || {};

  const [sequence] = useState((index ? index : phone ? Number(phone || 0) : 23) % 3);

  const returnObj: { avatarClass: string; path: any } = {
    avatarClass: "",
    path: null,
  };

  const maleAvatars = [maleAvatarImg1, maleAvatarImg2, maleAvatarImg3];
  const maleBG = ["secondary", "primary", "success"];
  const femaleAvatars = [femaleAvatarImg1, femaleAvatarImg2, femaleAvatarImg3];
  const femaleBG = ["success", "danger", "warning"];

  returnObj.avatarClass = file?.path
    ? "border"
    : gender === "Male"
    ? `p-[3px] bg-${maleBG[sequence]}-100`
    : gender === "Female"
    ? `p-[3px] bg-${femaleBG[sequence]}-100`
    : gender === null || gender === undefined
    ? `p-[3px] bg-neutral-200`
    : "";

  returnObj.path = file?.path
    ? file?.path
    : gender === "Male"
    ? maleAvatars[sequence]
    : gender === "Female"
    ? femaleAvatars[sequence]
    : gender === null || gender === undefined
    ? commonGenderImg
    : "";

  return returnObj;
};

// Custom hook to handle the state for expandToggleForm
export function useToggleForm() {
  const [isFormShow, setIsFormShow] = useState(true);

  const toggleForm = () => {
    setIsFormShow((prevState) => !prevState);
  };

  return [isFormShow, toggleForm];
}

// custom hook to timer countdown

const calculateTimeLeft = (targetDate: Date | undefined): Time => {
  if (!targetDate) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

  const now = newDate();
  let timeLeft: Time = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  if (now < targetDate) {
    timeLeft = {
      days: differenceInDays(targetDate, now),
      hours: differenceInHours(targetDate, now) % 24,
      minutes: differenceInMinutes(targetDate, now) % 60,
      seconds: differenceInSeconds(targetDate, now) % 60,
    };
  }
  return timeLeft;
};

const isTimeExpired = (timeLeft: Time): boolean => {
  return Object.values(timeLeft).every((n) => n === 0);
};

export const useCounter = () => {
  const [targetDate, setTargetDate] = useState<Date | undefined>(undefined);
  const [timeLeft, setTimeLeft] = useState<Time>(calculateTimeLeft(undefined));
  const isTimeOut = useRef<boolean>(false);

  useEffect(() => {
    if (!targetDate) {
      isTimeOut.current = false;
      return;
    }

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(newTimeLeft);
      if (isTimeExpired(newTimeLeft)) {
        clearInterval(timer);
        isTimeOut.current = true;
      } else {
        isTimeOut.current = false;
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return { timeLeft, setTargetDate, isTimeOut };
};

interface UIPagination {
  page: string | number;
  scrollPage: string | number;
  setScrollPage: (page: string | number) => void;
  limit: string | number;
  scrollLimit: number;
  filtersQuery: any;
  defaultPagination: string;
  setFilterQuery: (filters: any) => void;
}

export const defaultPagination = "?page=1&limit=10";

export const usePagination = (
  page: string | number = 1,
  limit: string | number = LocalStorageService.get("systemSettings")?.data_limit?.value || 10
): UIPagination => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { page: pageQuery, limit: limitQuery, filters: filterQuery } = searchParamsToObject();
  const [scrollPage, setScrollPage] = useState<string | number>(1);

  let mergeFilters = filterQuery || {};

  useEffect(() => {
    if (pageQuery) page = pageQuery;
    if (limitQuery) limit = limitQuery;
    setSearchParams(addSearchParams({ page, limit }).search);
  }, []);

  const setFilterQuery = (filters) => {
    mergeFilters = { ...mergeFilters, ...filters };
    setSearchParams(addSearchParams({ page: 1, filters: encode(mergeFilters) }).search);
  };

  return {
    page: pageQuery || page,
    limit: limitQuery || limit,
    scrollPage,
    setScrollPage,
    scrollLimit: infiniteScrollDataLimit(),
    filtersQuery: useMemo(() => mergeFilters, [JSON.stringify(mergeFilters)]),
    defaultPagination: defaultPagination,
    setFilterQuery: setFilterQuery,
  };
};
