interface IENV {
	ApiEndPoint: string | undefined;
	env: string | undefined;
	mapTilerKey: string | undefined;
}

const baseUrl = (): string => {
	const { hostname } = window.location;
	const {
		VITE_API_PROTOCOL: protocol,
		VITE_BASE_APP_URL: mainDomain,
		VITE_BASE_LAND_LORD_URL: landlordUrl,
		VITE_API_PATH: apiPath,
	} = import.meta.env;

	if (hostname === mainDomain) {
		return `${protocol}://${landlordUrl}${apiPath}`;
	}

	const subdomain = hostname.split(".")[0];
	return `${protocol}://${subdomain}.${landlordUrl}${apiPath}`;
};

export const ENV: IENV = {
	ApiEndPoint: baseUrl(),
	env: import.meta.env.MODE,
	mapTilerKey: import.meta.env.VITE_MAP_TILE_API_KEY,
};
