import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  ATTENDANCE_METHOD,
  ATTENDANCE_METHOD_EMPLOYEE_FROM,
  ATTENDANCE_METHOD_GENERAL_FROM,
  ATTENDANCE_POLICY,
  GROUPS,
  HR,
  HR_ATTENDANCE,
  OFFICE_LOCATIONS,
  OVERTIME_POLICY,
  SESSION,
  SHIFTS,
  TEAMS,
} from "./path-name.route";

export const HrManagementRoutes: IRouteList[] = [
  {
    path: HR,
    component: lazy(() => import("@pages/Settings/HRManagement/HRManagement")),
    meta: {
      action: hrmSettingsAcl.hrmManagement.parent.action,
      resource: hrmSettingsAcl.hrmManagement.parent.resource,
    },
    children: [
      {
        path: TEAMS,
        component: lazy(() => import("@pages/Settings/HRManagement/Tabs/Team/TeamList")),
        meta: {
          action: hrmSettingsAcl.hrmManagement.team.getAll.action,
          resource: hrmSettingsAcl.hrmManagement.team.getAll.resource,
        },
      },
      {
        path: GROUPS,
        component: lazy(() => import("@pages/Settings/HRManagement/Tabs/Group/GroupList")),
        meta: {
          action: hrmSettingsAcl.hrmManagement.group.getAll.action,
          resource: hrmSettingsAcl.hrmManagement.group.getAll.resource,
        },
      },
      {
        path: SHIFTS,
        component: lazy(() => import("@pages/Settings/HRManagement/Tabs/Shifts/Shift")),

        meta: {
          action: hrmSettingsAcl.hrmManagement.shift.getAll.action,
          resource: hrmSettingsAcl.hrmManagement.shift.getAll.resource,
        },
      },

      // {
      //   path: ATTENDANCE_DEVICES,
      //   component: lazy(
      //     () =>
      //       import(
      //         "@pages/Settings/HRManagement/Tabs/AttendanceDevice/AttendanceDevices"
      //       )
      //   ),
      //   meta: {
      //     action: hrmSettingsAcl.hrmManagement.attendanceDevice.getAll.action,
      //     resource: hrmSettingsAcl.hrmManagement.attendanceDevice.getAll.resource,
      //   },
      // },
      {
        path: OFFICE_LOCATIONS,
        component: lazy(() => import("@pages/Settings/HRManagement/Tabs/OfficeLocation/OfficeLocation")),
        meta: {
          action: hrmSettingsAcl.hrmManagement.locations.getAll.action,
          resource: hrmSettingsAcl.hrmManagement.locations.getAll.resource,
        },
      },
      {
        path: SESSION,
        component: lazy(() => import("@pages/Settings/HRManagement/Tabs/Session/Session")),
        meta: {
          action: hrmSettingsAcl.hrmManagement.locations.getAll.action,
          resource: hrmSettingsAcl.hrmManagement.locations.getAll.resource,
        },
      },
      // {
      //   path: ATTENDANCE_LOCATIONS,
      //   component: lazy(
      //     () =>
      //       import(
      //         "@pages/Settings/HRManagement/Tabs/AttendanceLocation/AttendanceLocation"
      //       )
      //   ),
      //   meta: {
      //     action: hrmSettingsAcl.hrmManagement.attendanceDevice.getAll.action,
      //     resource: hrmSettingsAcl.hrmManagement.attendanceDevice.getAll.resource,
      //   },
      // },
    ],
  },
];
