import brokenImg from "@assets/images/brokenIMG.jpg";
import defaultImg from "@assets/images/defaultImg.webp";

interface IImage {
  src?: string;
  priority?: "name" | "image";
  noBorder?: boolean;
  className?: string;
  name?: string;
  imgClassName?: string;
  onClick?: Function;
}

const Image = ({
  src = defaultImg,
  priority = "image",
  name,
  noBorder,
  className,
  imgClassName,
  onClick,
  ...rest
}: IImage) => {
  const matches = name?.match(/\b(\w)/g);
  const acronym = matches?.join("").toUpperCase();

  return (
    <div
      className={`${className && className} ${noBorder ? "" : "border border-neutral-100"} `}
      onClick={() => onClick && onClick()}
    >
      {priority === "image" ? (
        <img
          src={src}
          alt=""
          className={`${imgClassName} w-full h-full object-contain`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = brokenImg;
          }}
          {...rest}
        />
      ) : (
        <span className="cursor-pointer">{acronym}</span>
      )}
    </div>
  );
};

export default Image;
