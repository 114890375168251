import Bd from "@assets/flags/bd.svg";

export const job_types = [
  {
    name: "Full Time",
    enum_name: "Full time",
  },
  {
    name: "Part Time",
    enum_name: "Part time",
  },
  {
    name: "Contractual",
    enum_name: "Contractual",
  },
  {
    name: "Probationary",
    enum_name: "Probationary",
  },
  {
    name: "Permanent",
    enum_name: "Permanent",
  },
  {
    name: "Intern",
    enum_name: "Intern",
  },
];

export enum PayrollChannel {
  BANK = "Bank",
  DIGITAL_BANKING = "Digital Banking",
  CASH = "Cash",
}

export const HistoryDefaultData = {
  employee_id: "",
  company_name: "",
  job_title: "",
  address: "",
  job_type: "",
  responsibilities: "",
  start_date: "",
  end_date: "",
  reason_for_leaving: "",
  has_documents: false,
};

export const defaultPhoneCountry = {
  country: "Bangladesh",
  code: "+880",
  iso: "BD",
  flag: Bd,
  minLength: 5,
  maxLength: 10,
};
